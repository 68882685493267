import { useEffect } from 'react';
import { analyticsDictionary, callAnalyticVisit, createAnalyticAttrs } from '@analytics';
import { Button } from '@website/shared/ui/molecules';
import { ROUTES } from '@website/shared/lib/routes';
import { MainLayout } from '@website/shared/ui/layouts/main-layout';
import { ContentLayout } from '@website/shared/ui/layouts/content-layout';
import { Header } from '@website/widgets/header';
import WebDisconnected from './icons/web-disconnected.svg';
import styles from './styles.module.scss';
const Template404 = () => {
    const onClickGoToMainPage = () => {
        location.href = ROUTES.root;
    };
    useEffect(() => {
        callAnalyticVisit();
    }, []);
    return (<MainLayout>
      <Header />
      <MainLayout.Content>
        <ContentLayout>
          <div className={styles.template404}>
            <div className={styles.template404__container}>
              <div className={styles.template404__webDisconnected}>
                <WebDisconnected />
              </div>
              <span className={styles.template404__title}>Как-то тут тихо...</span>
              <span className={styles.template404__subTitle}>
                Кажется, на этой странице ничего нет — кроме числа 404.
              </span>
              <span className={styles.template404__subTitle}>Просто перейдите на главную страницу.</span>
              <Button className={styles.template404__button} onClick={onClickGoToMainPage} {...createAnalyticAttrs(analyticsDictionary.errorLinkMainButtonShow, ['show'])}>
                Перейти на главную
              </Button>
            </div>
          </div>
        </ContentLayout>
      </MainLayout.Content>
    </MainLayout>);
};
export default Template404;
